import React, { useState } from 'react';
import { navigate } from 'gatsby';

import Layout from '../../components/Layout';

import mondayConfig from '../../constants/monday-config';
import { downloadFile } from '../../utils/common';

import imgL from '../../assets/images/pages/descargar-el-folleto/mainImg.png';

import fileELEBarcelonafolleto from '../../assets/files/ELE-Barcelona-folleto.pdf';

import '../../assets/styles/pages/descargar-el-folleto.scss';

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
};

const DescargarElFoletto = () => {
  const [formData, setFormData] = useState(EMPTY_FORM_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const MondayData = {
      BOARD_ID: mondayConfig.boardEleBarcelonaLeadsId,
      GROUP_ID: mondayConfig.groupId,
      itemName: `${formData.firstName} ${formData.lastName}`,
      values: {
        status_3: { label: 'Folleto web' }, // Source - Fuente
        status_5: mondayConfig.status, // Estatus
        first_name: formData.firstName, // Primer nombre
        last_name: formData.lastName, // Apellidos
        email9: formData.userEmail,
        date5: mondayConfig.date, // contact date - Fecha contacto
      },
    };

    const response = await fetch('/api/monday', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(MondayData),
    });
    const jsonResponse = await response.json();

    downloadFile('ELE-Barcelona-folleto.pdf', fileELEBarcelonafolleto);

    if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
      setFormData(EMPTY_FORM_DATA);

      if (window?.fbq) {
        window.fbq('track', 'SubmitApplication');
      }

      navigate('/descargar-folleto/gracias');
    }
  };
  return (
    <Layout
      title="Descargar Folleto | ELE Barcelona"
      description="Descargar nuestro folleto y descubre los cursos de formación y más"
    >
      <div className="descargar-el-folleto">
        <section className="second-section">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-7 order-2">
                <div className="block_l">
                  <form id="brexit" onSubmit={handleSubmit} className="form">
                    <h1 className="c-title-22 form__title">Consigue ahora tu folleto gratis</h1>

                    <label className="form__first-name">
                      <p className="c-text-14 text-under">Primer nombre</p>
                      <input
                        className="input__first-name"
                        type="text"
                        placeholder="Pablo"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label className="form__last-name">
                      <p className="c-text-14 text-under">Apellidos</p>
                      <input
                        className="input__last-name"
                        type="text"
                        placeholder="González"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>

                    <label className="form__first-name email">
                      <p className="c-text-14 text-under">Correo electrónico</p>
                      <input
                        className="input__email"
                        type="email"
                        placeholder="example@gmail.com"
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <a href={fileELEBarcelonafolleto}>
                      <button type="submit" className="form__btn c-btn c-btn--green">
                        Descargar mi folleto
                      </button>
                    </a>
                  </form>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-5 order-1">
                <div className="block_r">
                  <h1 className="c-title-34">¡Descarga el folleto de ELE Barcelona!</h1>
                  <p className="c-text-18">
                    Si quieres saber más sobre cómo ser docente ELE, descárgate el syllabus del
                    programa.
                  </p>
                  <div className="block_r__img">
                    <img src={imgL} alt="" className="block_r__img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default DescargarElFoletto;
